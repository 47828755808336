<template>
  <div class="question">
    <div class="bg">
      <div class="center">
        <h4>常见问题</h4>
        <p>Common Problem</p>
      </div>
    </div>
    <div class="tit">
      <div class="left">
        <span>黑洞引擎</span>
        <span>星云协同平台</span>
      </div>
      <div class="right">
        <el-input v-model="search" prefix-icon="el-icon-search"></el-input>
      </div>
    </div>
    <div class="bottom">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose">
        <el-submenu index="1">
          <template slot="title">
            <span>问题一</span>
          </template>
            <el-menu-item index="1-1">问题1</el-menu-item>
            <el-menu-item index="1-2">问题2</el-menu-item>
            <el-menu-item index="1-3">问题3</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
            <template slot="title">
              <span>问题二
              </span>
            </template>
              <el-menu-item index="1-1">问题1</el-menu-item>
              <el-menu-item index="1-2">问题2</el-menu-item>
              <el-menu-item index="1-3">问题3</el-menu-item>
          </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        search: ''
      }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath)
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath)
      }
    }
  }
</script>

<style lang="less" scoped>
.question{
  height: 100%;
  .bg{
    background: url('../../assets/image/background/bg (7).jpg') center center;
    background-size: cover;
    height: 60vh;
    color: #fff;
    text-align: center;
    font-size: 60px;
    position: relative;
    p{
      font-size: 30px;
    }
  }
  .tit{
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #ccc;
    .left{
      margin-left: 100px;
      color: 16px;
      color: #666666;
      span:first-child{
        margin-right: 30px;
        color: #000;
        font-size: 18px;
      }
    }
    .right{
      margin-right: 50px;
      /deep/ .el-input__inner{
        border-radius: 20px;
      }
    }
  }
  .bottom{
    overflow-y: auto;
    // height: 80vh;
    // height: calc(100% - 60px);
    /deep/ .el-menu{
      width: 200px;
      height: 100%;
      .el-submenu__title{
        padding-left: 30px !important;
      }
      .el-submenu__icon-arrow{
        left: 10px;
        right: 0;
        margin-top: -5px;
        width: 12px;
      }
      .el-submenu>.el-submenu__title .el-submenu__icon-arrow{
          -webkit-transform: rotateZ(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotateZ(-90deg);
      }
      .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
          -webkit-transform: rotateZ(0deg);
          -ms-transform: rotate(0deg);
          transform: rotateZ(0deg);
      }
    }
  }
}
</style>
